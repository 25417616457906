<template>
  <fullscreen v-model="isFullScreen" class="background">
    <div class="header-bar">
      <div style="display: flex; align-items: center; color: #fffe; margin: 0 24px">
        <div style="flex: 1"></div>
        <div style="flex: 1; font-size: 30px; font-weight: bold; text-align: center">仓库数据可视化大屏</div>
        <div style="flex: 1; font-size: 16px; text-align: right">
          <div style="display: flex; justify-content: flex-end">
            <div>{{ currentTime }}</div>
            <div style="cursor: pointer; margin: 0 12px" @click="toggleFullScreen">
              <a-icon v-if="isFullScreen" type="fullscreen-exit" style="font-size: 24px" />
              <a-icon v-else type="fullscreen" style="font-size: 24px" />
            </div>
            <div style="cursor: pointer" @click="$router.go(-1)">
              <a-icon type="arrow-left" style="font-size: 24px" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; gap: 8px; height: calc(100vh - 80px); padding: 0 8px 8px 8px">
      <div style="height: 220px; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #a0d911">实时库存统计</div>
          <div class="card-content" style="display: flex; align-items: center; text-align: center">
            <div style="flex: 1">
              <div style="font-size: 24px; font-weight: bold">
                <span style="font-size: 24px">
                  {{ dataItem.inventory_total_quantity.toFixed(2) }}
                </span>
                <span style="font-size: 14px">件</span>
              </div>
              <div style="font-size: 14px">{{ $t("实时库存总数") }}</div>
            </div>
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #a0d911">库位状态</div>
          <div class="card-content" style="display: flex; align-items: center; text-align: center">
            <div style="flex: 1">
              <div style="font-size: 24px; font-weight: bold">{{ dataItem.idle_location_count }}</div>
              <div style="font-size: 14px">{{ $t("库位空闲") }}</div>
            </div>
            <div style="flex: 1">
              <div style="font-size: 24px; font-weight: bold">{{ dataItem.occupied_location_count }}</div>
              <div style="font-size: 14px">{{ $t("库位占用") }}</div>
            </div>
            <div style="flex: 1">
              <div style="font-size: 24px; font-weight: bold">{{ dataItem.locationPercentage }}%</div>
              <div style="font-size: 14px">{{ $t("利用率") }}</div>
            </div>
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #a0d911">库龄分析</div>
          <div class="card-content">
            <StockAgePanel />
          </div>
        </div>
      </div>
      <div style="flex: 1; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #1890ff">收货统计</div>
          <div class="card-content">
            <ReceiptPanel :item="dataItem" />
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #1890ff">上架统计</div>
          <div class="card-content">
            <ShelvePanel :item="dataItem" />
          </div>
        </div>
      </div>
      <div style="flex: 1; display: flex; flex-direction: row; gap: 8px">
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #ffa940">拣货统计</div>
          <div class="card-content">
            <PickingPanel :item="dataItem" />
          </div>
        </div>
        <div class="card" style="flex: 1">
          <div class="card-title" style="border-left: 4px solid #ffa940">复核统计</div>
          <div class="card-content">
            <DeliveryPanel :item="dataItem" />
          </div>
        </div>
      </div>
    </div>
  </fullscreen>
</template>

<script>
import { homeOverview } from "@/api/data";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    StockAgePanel: () => import("./StockAgePanel"),
    ReceiptPanel: () => import("./ReceiptPanel"),
    ShelvePanel: () => import("./ShelvePanel"),
    PickingPanel: () => import("./PickingPanel"),
    DeliveryPanel: () => import("./DeliveryPanel"),
  },
  data() {
    return {
      currentTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      timeUpdateInterval: null,
      dataUpdateInterval: null,
      isFullScreen: false,
      isReloadPage: false,

      dataItem: {
        inventory_total_quantity: 0,
        idle_location_count: 0,
        occupied_location_count: 0,
        locationPercentage: 0,
        finished_stock_in_quantity: 0,
        unfinished_stock_in_quantity: 0,
        stockInPercentage: 0,
        finished_shelve_quantity: 0,
        unfinished_shelve_quantity: 0,
        shelvePercentage: 0,
        finished_picking_quantity: 0,
        unfinished_picking_quantity: 0,
        pickingPercentage: 0,
        receipt_operator_items: [],
        shelve_operator_items: [],
        delivery_operator_items: [],
        picking_operator_items: [],
      },
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    queryData() {
      homeOverview({ warehouse: this.currentWarehouse }).then((data) => {
        const stockInTotal = data.finished_stock_in_quantity + data.unfinished_stock_in_quantity;
        data.stockInPercentage = NP.divide(data.finished_stock_in_quantity, stockInTotal, 0.01);
        data.stockInPercentage = NP.round(data.stockInPercentage, 0);

        const stockOutTotal = data.finished_stock_out_quantity + data.unfinished_stock_out_quantity;
        data.stockOutPercentage = NP.divide(data.finished_stock_out_quantity, stockOutTotal, 0.01);
        data.stockOutPercentage = NP.round(data.stockOutPercentage, 0);

        const shelveTotal = data.finished_shelve_quantity + data.unfinished_shelve_quantity;
        data.shelvePercentage = NP.divide(data.finished_shelve_quantity, shelveTotal, 0.01);
        data.shelvePercentage = NP.round(data.shelvePercentage, 0);

        const pickingTotal = data.finished_picking_quantity + data.unfinished_picking_quantity;
        data.pickingPercentage = NP.divide(data.finished_picking_quantity, pickingTotal, 0.01);
        data.pickingPercentage = NP.round(data.pickingPercentage, 0);

        data.idle_location_count = NP.minus(data.total_location_count, data.occupied_location_count);
        data.locationPercentage = NP.divide(data.occupied_location_count, data.total_location_count, 0.01);
        data.locationPercentage = NP.round(data.locationPercentage, 0);
        this.dataItem = data;
      });
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.isReloadPage = true;
      setTimeout(() => (this.isReloadPage = false), 100);
    },
  },
  mounted() {
    this.timeUpdateInterval = setInterval(() => (this.currentTime = moment().format("YYYY-MM-DD HH:mm:ss")), 500);
    this.queryData();
    this.dataUpdateInterval = setInterval(() => this.queryData(), 3000);
  },
  beforeDestroy() {
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
      this.timeUpdateInterval = null;
    }

    if (this.dataUpdateInterval) {
      clearInterval(this.dataUpdateInterval);
      this.dataUpdateInterval = null;
    }
  },
};
</script>

<style scoped>
.background {
  background-image: url("./background.jpg");
  height: 100%;
}

.header-bar {
  height: 80px;
  background-image: url("./headerBar.png");
  background-size: 100% 100%;
  padding-top: 8px;
}

.card {
  height: 100%;
  padding: 8px 16px;
  background: rgba(6, 48, 109, 0.5);
}

.card-title {
  color: #fff;
  font-size: 14px;
  padding-left: 8px;
  height: 20px;
}

.card-content {
  height: calc(100% - 20px);
  color: #fffe;
}
</style>
